import { Customer } from './../customer.model';
import { Contractor } from './../contractor.model';
import { Service } from './../service/service.model';
import { Serializable } from '../deserialize.service';
import * as moment from 'moment';

export class ReservationNew implements Serializable<ReservationNew> {
    bsid: number;
    caseId: string;
    contractor: Contractor;
    customer: Customer;
    from: moment.Moment;
    isBs: boolean;
    noOfReservations: number;
    service: Service;
    to: moment.Moment;

    deserialize(input) {
        this.bsid = input.bsid;
        this.caseId = input.caseId;
        if (input.contractor) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        this.customer = new Customer().deserialize(input.customer);
        this.from = moment(input.from);
        if (input.contractor) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        this.isBs = input.isBs;
        this.noOfReservations = input.noOfReservations;
        if (input.service) {
            this.service = new Service().deserialize(input.service);
        }
        this.to = moment(input.to);
        return this;
    }
}
