import { Service } from './service/service.model';
import { Serializable } from './deserialize.service';
import { Contractor } from './contractor.model';
import { Customer } from './customer.model';

export class Reservation implements Serializable<Reservation> {
    reservationFrom: Date;
    reservationTo: Date;
    visitId: number;
    aggregateId: number;
    prereservationId: string;
    status: number;
    service: Service;
    contractor: Contractor;
    customer: Customer;
    visitEndDate: Date;
    comment: string;

    deserialize(input: any) {
        this.reservationFrom = input.reservationFrom;
        this.reservationTo = input.reservationTo;
        this.visitId = input.visitId;
        this.aggregateId = input.aggregateId;
        this.prereservationId = input.prereservationId;
        this.status = input.status;
        if (input.service) {
            this.service = new Service().deserialize(input.service);
        }
        if (input.contractor) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        this.comment = input.comment;
        return this;
    }
}
