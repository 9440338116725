import { Serializable } from './deserialize.service';
import * as moment from 'moment';

export class HistoryLogDetails implements Serializable<HistoryLogDetails> {
    caseInternalId: string;
    description: string;
    prereservationId: string;
    caseId: string;
    reservationFrom: moment.Moment;
    reservationTo: moment.Moment;
    serviceName: string;
    serviceUid: any;
    status: any;
    userId: number;
    subcontractorName: string;
    //naknadno spreminjanje vrednosti/kritja storitve (SEPARATED only)
    totalPrice: number;
    coveragePrice: number

    deserialize(input) {
        this.caseInternalId = input.caseInternalId;
        this.description = input.description;
        this.prereservationId = input.prereservationId;
        this.caseId = input.caseId;
        this.reservationFrom = input.reservationFrom;
        this.reservationTo = input.reservationTo;
        this.serviceName = input.serviceName;
        this.serviceUid = input.serviceUid;
        this.status = input.status;
        this.userId = input.userId;
        this.subcontractorName = input.subcontractorName;
        if(input.totalPrice) this.totalPrice = input.totalPrice;
        if(input.coveragePrice) this.coveragePrice = input.coveragePrice;
        return this;
    }
}
