import { Slot } from './slot.model';
import { Serializable } from './deserialize.service';
import { Contractor } from './contractor.model';
import * as _ from 'lodash';

export class Subcontractor implements Serializable<Subcontractor> {
    bgColor: string;
    color: string;
    externalId: string;
    fgColor: string;
    id: number;
    name: string;
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        this.bgColor = input.bgColor;
        this.color = input.color;
        this.externalId = input.externalId;
        this.fgColor = input.fgColor;
        this.id = input.id;
        this.name = input.name;
    }
}
