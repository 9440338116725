import { Contractor } from './../contractor.model';
import { AuthorityCase } from './../authorityCase/authorityCase.model';
import { Serializable } from '../deserialize.service';
import { Customer } from '../customer.model';

export class TriageResponse implements Serializable<TriageResponse> {
    public id: number;
    public created: Date;
    public caseAuthority: AuthorityCase;
    public contractor: Contractor;
    public customer: Customer;

    deserialize(input: any) {
        this.created = input.created;
        this.id = input.id;
        this.caseAuthority = input.caseAuthority;
        this.contractor = input.contractor;
        this.customer = input.customer;
        return this;
    }
}
